import React from 'react'
import styled from '@emotion/styled';

import Section from '@components/Section';
import Headings from "@components/Headings";
import { LinkInternal } from "@components/LinkNav";
import mediaqueries from '@styles/media';
import { IAuthor } from '@types';

const CategoryHero: React.FC<IAuthor> = ({ category, maxWidth, counter }) => {

  return (
    <HeadingContainer style={{ maxWidth: `${maxWidth}px` }}>
      <Headings.h1>
        {category.name}
          <Sup>{counter + " lessons"}</Sup>
        </Headings.h1>
      {/* <InfoText>
        {category.bio}
      </InfoText> */}
    </HeadingContainer>
  );
};

export default CategoryHero;

const HeadingContainer = styled.div`
  padding: 0 0 40px 0;
  width: 100%;

  ${mediaqueries.tablet`
    padding: 0 24px 40px 24px;
  `}

  & h1 {
    ${mediaqueries.tablet`
      font-size: 48px;
      line-height: 40px;
    `}
  }
`;

const Sup = styled.sup`
  font-size: 24px;
  margin-left: 8px;
  width: 100%;
  ${p => p.theme.textGradient};
  vertical-align: text-top;
  letter-spacing: -1px;
  font-family: ${p => p.theme.fonts.body};
  font-weight: ${p => p.theme.fontsWeight.regular};
`;