import React from 'react';
import styled from '@emotion/styled';
import { graphql, useStaticQuery } from "gatsby";

import mediaqueries from '@styles/media';
import Section from '@components/Section';
import SEO from '@components/SEO';
import Layout from '@components/Layout';
import Paginator from '@components/Navigation/Navigation.Paginator';
import NavCategory from '@components/Navigation/Navigation.Categories';

import ArticlesList from "../sections/articles/Articles.List";
import CategoryHero from "../sections/category/Category.Hero";

import { Template } from "@types";

const siteQuery = graphql`
  {
    allSite {
      edges {
        node {
          siteMetadata {
            title
            hero {
              maxWidth
            }
          }
        }
      }
    }
  }
`;
const CategoryPage: Template = ({ location, pageContext }) => {
    // const { group: articles, category } = pageContext;
    // const authors = pageContext.additionalContext.authors;

    const results = useStaticQuery(siteQuery);
    const title = results.allSite.edges[0].node.siteMetadata.title;
    const maxWidth = results.allSite.edges[0].node.siteMetadata.hero.maxWidth;
    
    const author = pageContext.additionalContext.author;
    const articles = pageContext.group;
    const roundLength = articles.length > 10 ? Math.round(articles.length / 10) * 10 : articles.length;
    const roundLengthToSring = articles.length > 10 ? roundLength + "+" : roundLength;
  
    return (
      <Layout>
        <SEO pathname={location.pathname} title={roundLengthToSring + " Visual Lessons in " + author.name + " - " + title} />
        <Section narrow>
          <LayoutContainer>
            <CategoryBarWrap>
              <CategoryBar>
                <NavCategory />
              </CategoryBar>
            </CategoryBarWrap>
            <div>
              <CategoryHero category={author} maxWidth={maxWidth} counter={articles.length}/>
              <ArticlesList articles={articles} />
              <ArticlesPaginator show={pageContext.pageCount > 1}>
                <Paginator {...pageContext} />
              </ArticlesPaginator>
            </div>
          </LayoutContainer>
        </Section>
      </Layout>
    );
  }

export default CategoryPage;

const ArticlesPaginator = styled.div<{ show: boolean }>`
  ${p => p.show && `margin-top: 64px;`}
`;

const LayoutContainer = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 288px 1fr;
  grid-template-rows: 2;
  column-gap: 32px;
  row-gap: 32px;

  ${mediaqueries.desktop_medium`
    grid-template-columns: 1fr;
  `}
`;

const CategoryBarWrap = styled.div`
  position: relative;
  z-index: 100;
`;

const CategoryBar = styled.div`
  position: sticky;
  top: 16px;
`;
